<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW MOTOR FINANCE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-layout row wrap class="align-center mx-2 ">
          <v-flex xs12 md3>
            <v-select
              v-model="selection"
              class="mx-2"
              dense
              outlined
              label="Selection"
              :items="['Employee','Non-Employee']"
              @change="selected"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 v-if="selection==='Employee'">
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-text="category"
              item-value="id"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 v-if="selection==='Employee'">
            <v-autocomplete
              dense
              outlined
              v-model="search"
              :items="search_items"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="Search"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
              @change="get_search_items_info"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2" v-if="selection==='Employee'">
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="name"
              label="Name"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="branch"
              label="Branch"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2" v-else>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="name"
              label="Name"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="branch"
              label="Branch"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2 mt-9">
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="brand"
              label="Brand/Unit"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="dealer"
              label="Dealer"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="model"
              label="Model/Color"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="terms"
              label="Terms (months)"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="amount"
              label="Amount"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="interest"
              label="Interest"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="total_interest"
              label="Total Interest"
              dense
              disabled
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="total"
              label="Grand Total"
              dense
              readonly
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="monthly_due"
              label="Monthly Due"
              dense
              disabled
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="due_date"
              label="Due Date"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="downpayment"
              label="Down Payment"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <!-- alert -->
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            class="me-3 mt-4"
            @click="save_employee"
            v-if="!saving"
          >
            Save changes
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-form>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Name
            </th>
            <th class="text-uppercase">
              Branch
            </th>
            <th class="text-uppercase">
              Amount
            </th>
            <th class="text-uppercase">
              Details
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in active_items"
            :key="item.id"
          >
            <td>
              {{ item.employee===null?item.name:item.employee.last_name
              +', '+item.employee.first_name+' '+item.employee.middle_name }}
            </td>
            <td>
              {{ item.employee===null?item.branch:item.employee.branch.branch_code }}
            </td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>
            <td>
              {{ item.purpose }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <!-- alert -->
      <v-col cols="12" v-show="alert2">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message2}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12" v-if="active_items.length>0">
        <v-btn
          color="success"
          class="me-3 mt-4"
          @click="approved"
          v-if="!saving2"
        >
          Approved
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      saving2: false,
      alert2: false,
      alert_message2: '',

      selection: '',
      category_id: '',
      category_items: [],
      active_items: [],

      search: '',
      search_items: [],
      isLoading: false,

      name: '',
      branch: '',

      employee_id: 0,
      downpayment: 0,
      due_date: '',
      monthly_due: 0,
      total: 0,
      total_interest: 0,
      interest: 0,
      amount: 0,
      terms: 0,
      model: '',
      dealer: '',
      brand: '',

      particulars: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      ...mapActions('employee', ['search_active_employee',]),
      ...mapActions('motor_finance', ['register_motor_finance', 'list_of_not_yet_approved_motor_finance', 'approved_motor_finance']),
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      initialize_data() {
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.list_of_not_yet_approved_motor_finance()
          .then(response => {
            this.active_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected() {
        this.employee_id = 0
      },
      selected_category() {
        this.name = ''
        this.branch = ''
        this.search_items = []
        this.search = ''
        this.employee_id = 0
      },
      searching(value) {
        this.name = ''
        this.branch = ''
        this.employee_id = 0

        this.isLoading = true
        this.search_active_employee({
          search_word: value,
          category_id: this.category_id,
        })
          .then(response => {
            this.search_items = response.data
            this.isLoading = false
          })
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.employee_id = this.search_items[index].id
            this.name = this.search_items[index].name
            this.branch = this.search_items[index].details.branch.branch_code
          } else {
            this.name = ''
            this.branch = ''
            this.employee_id = 0
          }
        } else {
          this.name = ''
          this.branch = ''
          this.employee_id = 0
        }
      },
      calculation() {
        var to_iner = parseFloat(this.terms) * parseFloat(this.interest)
        this.total_interest = (parseFloat(this.amount) * (parseFloat(to_iner) / 100))
        this.total = parseFloat(this.amount) + parseFloat(this.total_interest)
        this.monthly_due = parseFloat(this.total) / parseFloat(this.terms)
      },
      approved() {
        this.saving2 = true
        this.alert2 = false
        this.approved_motor_finance()
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving2 = false
            this.reset()
          })
          .catch(error => {
            this.alert2 = true
            this.alert_message2 = error
            this.saving2 = false
          })
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('employee_id', this.employee_id);
          data.append('amount', this.total);
          data.append('name', this.name.toUpperCase());
          data.append('branch', this.branch.toUpperCase());
          data.append('date', this.due_date);
          data.append('purpose', 'Brand-' + this.brand + ' # Dealer-' + this.dealer + ' # Model-' + this.model + ' # Terms-' + this.terms + ' # Int(%)-' + this.interest + '% # Total-' + this.formatPrice(this.amount) + ' # Interest-' + this.formatPrice(this.total_interest) + ' # GTotal-' + this.formatPrice(this.total) + ' # Monthly-' + this.formatPrice(this.monthly_due) + ' # DueDate-' + moment(this.due_date,'YYYY-MM-DD').format('MM-DD-YYYY') + ' # DownPayment-' + this.formatPrice(this.downpayment));
          this.register_motor_finance(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving = false
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  }
</script>
